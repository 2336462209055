<template>
  <div>
    <div class="row gutter-b">
      <div class="col-md-12">
        <div class="card card-custom">
          <div class="card-header card-header-fixed border-0 px-6">
            <div class="row align-items-center col-10 px-0">
                  <div class="col-3 pr-0">
                    <div class="input-icon">
                      <input 
                        type="text" 
                        class="form-control form-control-solid" 
                        :placeholder="$t('mealsystem.placeholder.search')"
                        v-model="search"
                        >
                      <span>
                        <i class="flaticon2-search-1 text-muted"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-3">
                    <vs-select v-if="categories" v-model="searchCategory" :options="categories" :placeholder="$t('mealsystem.placeholder.search_categories')" label="name" :reduce="categories => categories.diners_category_id"/>
                  </div>
                  <div class="col-3">
                    <vs-select v-if="departments" v-model="searchDepartment" :options="departments" :placeholder="$t('mealsystem.placeholder.search_departments')" label="name" :reduce="departments => departments.department_id"/>
                  </div>
            
                  <b-button
                    variant="secondary"
                    class="mr-3 ml-3 pr-2" @click.prevent="removeFilters">
                    <i class="fa fa-times icon-md"></i>
                  </b-button>
                  <b-button
                    variant="primary" @click.prevent="searchDiners">
                  {{ $t("button.filter") }}   
                  </b-button>

              </div>
              <div class="card-toolbar">
                <router-link
                :to="{ name: 'mealsystem_diners_new'}"
                >
                  <b-button variant="success">
                    <span class="svg-icon svg-icon-md svg-icon-white" >
                      <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
                    </span>
                    {{ $t("mealsystem.diners.button.create") }}
                  </b-button>
                </router-link>
              </div>

          </div>
        </div>
      </div>
    </div>

    <!--begin::Card-->
    <div class="card card-custom" >
      <!--begin::Body-->
      <div class="card-body">
        <div class="table-responsive ">
          <table class="table table-head-custom table-head-bg table-vertical-center">
            <thead>
              <tr class="text-left text-uppercase">
                <th width="20"></th>
                <th width="180">{{ $t("mealsystem.diners.label.diner_number") }}</th>
                <th>{{ $t("mealsystem.diners.label.diner_fullname") }}</th>
                <th>{{ $t("mealsystem.diners.label.category") }}</th>
                <th>{{ $t("mealsystem.diners.label.department") }}</th>
                <th>{{ $t("mealsystem.diners.label.amount") }}</th>
                <th width="120">{{ $t("mealsystem.diners.label.status") }}</th>
              </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="(diner, index) in diners.data" :key="index">
                  <td class="pl-0">
                    <router-link
                      :to="{ name: 'mealsystem_diners_detail', params: { id: diner.diner_id }}"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <inline-svg src="/media/svg/icons/General/Search.svg" />
                      </span>
                    </router-link>
                  </td>
                  
                  <td>
                    <span class="font-weight-bolder">{{ diner.diner_number }}</span>
                  </td>

                  <td>
                    <span>{{ diner.first_name }} {{ diner.last_name }}</span>
                  </td>

                   <td>
                    <span>{{ diner.category.name }}</span>
                  </td>

                  <td>
                    <span>{{ diner.department.name }}</span>
                  </td>

                   <td class="text-success font-weight-bolder">
                    <span>{{ diner.amount }},-</span>
                  </td>

                  <td>
                    <span v-if="diner.discarded_at"><b-badge variant="danger">{{ $t("mealsystem.diners.title.unactive") }}</b-badge></span>
                    <span v-else><b-badge variant="success">{{ $t("mealsystem.diners.title.active") }}</b-badge></span>
                  </td>

                </tr>
            </tbody>
          </table>
          <Pagination :data="diners.meta" v-if="!loading"></Pagination>
        </div>
      </div>
      <!--end::Body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Pagination from "@/view/content/Pagination";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      loading: false,
      diners: null,
      page: 1,
      search: "",
      searchDepartment: null,
      departments: null,
      searchCategory: null,
      categories: null
    };
  },
  components: {
    Pagination
  },
  beforeMount() {
    // this.checkLastSearch();
    this.getDiners();
    this.getCategories();
    this.getDepartments();
  },
    metaInfo () { 
      return { title: this.$t("mealsystem.meta.diners_list")} 
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("mealsystem.meta.diners"), subtitle: this.$t("mealsystem.meta.diners_list") }]);
  },
  computed: {
    perpage() {
      return this.$store.state.pagination.pagination.itemsPerPage;
    },
    gridSearch: {
      get () {
        return this.$store.state.search.gridSearch;
      },
      set (value) {
        this.$store.commit('setGridSearch', value);
      }
    },
  },
  methods: {
    getDiners() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "/meal-system/diners?page=" + this.page + "&perpage=" + this.perpage + "&search=" + this.search + "&departments=" + this.searchDepartment + "&categories=" + this.searchCategory)
        .then((response) => {
          this.diners = response.data;
          this.loading = false;
        });
    },
    onUpdatePage(newPage) {
      this.page = newPage;
      this.getDiners();
    },
    searchDiners(){
      this.page = 1;
      this.getDiners(); 
      this.dinerSearch = {
        search: this.search
      } 
    },
    removeFilters(){
      this.page = 1;
      this.search = "";
      this.searchDepartment = null;
      this.searchCategory = null;
      this.dinerSearch = {
        search: this.search
      };
      this.getDiners(); 
    },
    getCategories() {  
        this.loading = true;
        ApiService.setHeader();
        ApiService.apiGet("/meal-system/diners/categories")
        .then((response) => {
          this.categories = response.data.data;
          this.loading = false;
        });
      },
      getDepartments() {  
        this.loading = true;
        ApiService.setHeader();
        ApiService.apiGet("/meal-system/departments")
        .then((response) => {
          this.departments = response.data.data;
          this.loading = false;
        });
      },
  }
};
</script>
